import styled from 'styled-components';
export const StyledLabel = styled.label`
	display: block;
	font-size: 0.9em;
	margin-bottom: 5px;
`;

export const StyledInput = styled.input`
	background-color: ${(props) => props.theme.articleBackgroundColor};
	border-color: ${(props) => props.theme.borderColor};
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: ${(props) => props.theme.darkText};
	font: inherit;
	font-size: 1em;
	line-height: 1em;
	margin: 0;
	margin-bottom: 16px;
	-moz-outline-radius: 0;
	padding: 12px 16px;
	width: 100%;
	&:focus {
		box-shadow: 0 0 1px 4px ${(props) => props.theme.borderColorActive};
		outline: 0;
	}
`;

export const StyledSelect = styled.select`
	background-color: ${(props) => props.theme.articleBackgroundColor};
	border-color: ${(props) => props.theme.borderColor};
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: ${(props) => props.theme.darkText};
	font-size: 1em;
	line-height: 1em;
	margin: 0;
	margin-bottom: 16px;
	-moz-outline-radius: 0;
	padding: 12px 16px;
	width: 100%;
	&:focus {
		box-shadow: 0 0 1px 4px ${(props) => props.theme.borderColorActive};
		outline: 0;
	}
`;

export const StyledForm = styled.div`
	margin: 0;
	padding: 0;
`;

export const StyledFilesTable = styled.table`
	font-size: 1em;
	min-width: 60ch;
	overflow-wrap: break-word;
	width: 100%;
	word-wrap: break-word;
	button {
		border: 1px solid ${(props) => props.theme.borderColor};
		margin: 0;
		padding: 3px 4px;
		text-align: center;
	}

	a {
		color: ${(props) => props.theme.darkText};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.position {
		text-align: center;
		width: 6em;
		span {
			display: inline-block;
			width: 2em;
		}
	}
	.name {
		text-align: left;
		width: 30em;
	}
	.date {
		text-align: center;
		width: 19em;
	}

	.delete {
		text-align: center;
		width: 5em;
	}
`;
