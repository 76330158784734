import styled from 'styled-components';

export const StyledLabel = styled.label`
	display: block;
	font-size: 0.9em;
	margin-bottom: 5px;
`;

export const StyledInput = styled.input`
	background-color: ${(props) => props.theme.articleBackgroundColor};
	border-color: ${(props) => props.theme.borderColor};
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: ${(props) => props.theme.darkText};
	font-size: 1em;
	line-height: 1em;
	margin: 0;
	margin-bottom: 16px;
	-moz-outline-radius: 0;
	padding: 12px 16px;
	width: 100%;
	&:focus {
		box-shadow: 0 0 1px 4px ${(props) => props.theme.borderColorActive};
		outline: 0;
	}
`;

export const StyledArea = styled.textarea`
	background-color: ${(props) => props.theme.articleBackgroundColor};
	border-color: ${(props) => props.theme.borderColor};
	border-radius: 0;
	border-style: solid;
	border-width: 1px;
	color: ${(props) => props.theme.darkText};
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
		Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 1em;
	height: 5.6em;
	line-height: 1.4em;
	margin: 0;
	margin-bottom: 16px;
	-moz-outline-radius: 0;
	padding: 12px 16px;
	resize: none;
	width: 100%;
	&:focus {
		box-shadow: 0 0 1px 4px ${(props) => props.theme.borderColorActive};
		outline: 0;
	}
`;

export const StyledInfoText = styled.div`
	font-size: 0.85em;
	padding-bottom: 8px;
`;

export const StyledError = styled.div`
	background-color: ${(props) => props.theme.errorBackgroundColor};
	color: ${(props) => props.theme.errorText};
	line-height: 1.5;
	margin: 8px 0;
	padding: 16px;
`;

export const StyledInfo = styled.div`
	background-color: ${(props) => props.theme.infoBackgroundColor};
	color: ${(props) => props.theme.infoText};
	line-height: 1.5;
	margin: 8px 0;
	padding: 16px;
`;

export const StyledGap = styled.div`
	margin-top: 16px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	padding: 16px 0 8px 0;
`;

export const StyledForm = styled.form`
	margin: 0;
	padding: 0;
`;

export const MessageDiv = styled.div`
	font-size: 1em;
	line-height: 1.6em;
	padding: 8px 16px 8px 16px;
	h2,
	h3 {
		color: ${(props) => props.theme.h3Color};
	}
`;
