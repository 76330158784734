import {
	ButtonContainer,
	MessageDiv,
	StyledError,
	StyledGap,
} from '../../../styles/formularz';
import React, { useEffect, useState } from 'react';
import { StyledArticle, StyledButton } from '../../../styles';
import {
	StyledForm,
	StyledInput,
	StyledLabel,
	StyledSelect,
} from '../../../styles/przetarg';

import { Layout } from '../../../components/Layout';
import { StyledH2 } from '../../../styles/common';
import axios from '../../../config/axios';
import { navigate } from 'gatsby';
import { useAuth } from '../../../hooks/useAuth';

import { SEO } from '../../../components/SEO';

export const Head = () => (
	<SEO 
	title='Przetarg - dodawanie'
	description='Dodawanie przetargu.'
	/>);
	
const isBrowser = () => typeof window !== 'undefined';

const DodajPage = () => {
	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	const [status, setStatus] = useState('przetarg trwający');
	const [id, setId] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		auth.verifyUser();
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/konto/logowanie/');
		}
	}, []);

	const addTender = async (e: any) => {
		e.preventDefault();
		if (!isValidForm()) {
			return;
		}
		const _id = id.replace(/\//g, '_');
		try {
			const response = await axios.post('/add_tender.php', {
				status,
				id: _id.trim(),
			});
			if (response?.status === 201) {
				navigate(`/konto/przetargi/edytuj?id=${_id}`);
			}
		} catch (e: any) {
			// console.log(e.response?.status);
			if (e.response?.status === 400) {
				setError(`Nieprawidłowe dane w formularzu.`);
				scroll(0, 0);
			}
			if (e.response?.status === 409) {
				setError(`Formularz zawiera błędy: Podany numer już istnieje.`);
				scroll(0, 0);
			}
			if (e.response?.status === 401) {
				auth.signOut();
			}
		}
	};

	const isValidForm = () => {
		let error = '';
		const regex =
			status[0] === 'p' ? /[0-9]{3}\/[0-9]{4}/ : /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;
		if (!id.match(regex)) {
			error = 'Nieprawidłowy numer.';
		}
		if (error) {
			setError(`Formularz zawiera błędy: ${error}`);
			scroll(0, 0);
			return false;
		} else {
			setError('');
			return true;
		}
	};

	if (!auth.user) {
		return null;
	}

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Przetarg - dodawanie
				</StyledH2>
				<MessageDiv>
					<StyledForm>
						{error ? <StyledError>{error}</StyledError> : <StyledGap />}
						<StyledLabel htmlFor="status">Status:</StyledLabel>
						<StyledSelect
							id="status"
							name="status"
							value={status}
							onChange={(e) => setStatus(e.currentTarget.value)}
						>
							<option value="przetarg archiwum">przetarg archiwum</option>
							<option value="przetarg trwający">przetarg trwający</option>
							<option value="przetarg zakończony">przetarg zakończony</option>
							<option value="sprzedaż archiwum">sprzedaż archiwum</option>
							<option value="sprzedaż trwająca">sprzedaż trwająca</option>
							<option value="sprzedaż zakończona">sprzedaż zakończona</option>
						</StyledSelect>
						<br />
						<StyledLabel htmlFor="tenderId">Numer:</StyledLabel>
						<StyledInput
							autoCapitalize="none"
							autoCorrect="off"
							id="tenderId"
							type="text"
							name="tenderId"
							spellCheck="false"
							pattern={
								status[0] === 'p'
									? '[0-9]{3}/[0-9]{4}'
									: '[0-9]{2}/[0-9]{2}/[0-9]{4}'
							}
							placeholder={status[0] === 'p' ? 'NNN/RRRR' : 'DD/MM/RRRR'}
							value={id}
							onChange={(e) => {
								setId(e.target.value);
							}}
						/>
						<ButtonContainer>
							<StyledButton
								type="button"
								onClick={() => {
									navigate('/konto/przetargi/lista');
								}}
							>
								Anuluj
							</StyledButton>
							<StyledButton type="button" onClick={addTender} marginleft="16px">
								Dalej
							</StyledButton>
						</ButtonContainer>
					</StyledForm>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
};

export default DodajPage;
